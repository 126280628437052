import { IMaxLoanTable, ILtvCalcInput, ILtvCalcOutput } from './types';

export const MinHousePrice = 70000;
const tblHealthy: IMaxLoanTable = {
  55: 29.5,
  56: 30.5,
  57: 31.5,
  58: 33,
  59: 34,
  60: 36.2,
  61: 37.2,
  62: 38.3,
  63: 39.3,
  64: 40.3,
  65: 41.3,
  66: 42.4,
  67: 43.4,
  68: 44.5,
  69: 45.5,
  70: 46.5,
  71: 47.6,
  72: 48.6,
  73: 49.6,
  74: 50.6,
  75: 51.7,
  76: 52.7,
  77: 53.8,
  78: 54.8,
  79: 55.8,
  80: 57,
  81: 58,
  82: 58.24,
  83: 59.28,
  84: 59.28,
  85: 59.28,
  86: 59.28,
  87: 59.28,
  88: 59.28,
  89: 59.28,
  90: 59.28,
};

const tblUnhealthy: IMaxLoanTable =  {
  55: 43.6,
  56: 44.9,
  57: 46,
  58: 47.2,
  59: 48.5,
  60: 49.5,
  61: 50.3,
  62: 51.5,
  63: 52.4,
  64: 53.5,
  65: 54.4,
  66: 54.5,
  67: 54.5,
  68: 54.5,
  69: 54.5,
  70: 54.5,
  71: 54.5,
  72: 54.5,
  73: 54.5,
  74: 54.5,
  75: 54.5,
  76: 54.5,
  77: 54.5,
  78: 54.5,
  79: 54.5,
  80: 54.5,
  81: 54.5,
  82: 54.5,
  83: 54.5,
  84: 54.5,
  85: 54.5,
  86: 54.5,
  87: 54.5,
  88: 54.5,
  89: 54.5,
  90: 54.5
}
// const isValid = x => (typeof x !== 'undefined' && x !== null && !isNaN(x));

export const getLtvValue = (input: ILtvCalcInput): ILtvCalcOutput => {
  const { clientAge, housePrice, ltvPlan } = input;
  const age = (clientAge > 90) ? 90 : clientAge;

  const valHealthy: number = tblHealthy[age];
  const MinLoan = (housePrice < 200000) ? 10000 :  Math.round(housePrice*0.05);
  const Healthy = Math.round( housePrice * 0.01 * valHealthy);
  const Unhealthy = Math.round( housePrice * 0.01 * tblUnhealthy[age]);
  const plans = { MinLoan, Healthy, Unhealthy };
  const result: ILtvCalcOutput = { plans, max: valHealthy, diff: 0, pct: {} };
  if (result.max > result.plans.Unhealthy) {
    result.max = result.plans.Unhealthy
  }
  result.diff = result.max - result.plans.MinLoan;
  result.pct = { 'MinLoan': result.plans.MinLoan * 100 / housePrice, 'Healthy': tblHealthy[age], 'Unhealthy': tblUnhealthy[age] };
  console.log('ltvPlan=', ltvPlan, result)
  return result;
};
