import React, { useState } from 'react';
import { ILtvConfig } from './../../config/types';
import { Centered, Footer } from './../Layout';
import { TextInput, RangerInput } from '../Form/index';
import { LevelsOfEnhancement } from './LevelsOfEnhancement';
import { getLtvValue } from './../../services/LtvCalc';
import { ILtvCalcInput } from './../../services/types';

export const Header = (props: any) => {
  const config: ILtvConfig = props.config;
  return (
    <header>
      <h1 className="title">{config.titles.header}</h1>
      {(config.titles.intro) ? (
        <div className="intro">{config.titles.intro}</div>
      ): null}
    </header>
  );
};

export const defaultInput: ILtvCalcInput = {
  clientAge: 55,
  housePrice: 400000  // G6
};

const ClientDetails = (props: any) => {
  const input: ILtvCalcInput = props.input;
  const setInput: Function = props.setInput;
  const config: ILtvConfig = props.config;
  return  (
    <div className="section">
      <div className="title">{config.titles.detailsTitle}</div>
      <div className="section-row">
        <div className="column">
          <div className="label">{config.titles.ageTitle}</div>
          <div style={{ paddingTop: 10 }}>
            <RangerInput
              value={input.clientAge} min={55} max={95} withLabel={true}
              color="#ffdd03"
              fontColor="black"
              inactiveColor='#bbbbbb'
              onChange={(val: number) => { setInput({ ...input, clientAge: val }) }}
            />
          </div>
        </div>
        <div className="small-column">
          <div className="label">{config.titles.valueTitle}</div>
          <div>
            <TextInput
              width={150}
              type="currency" 
              prefixes="&pound;" 
              value={input.housePrice} 
              onChange={(val: number) => { setInput({ ...input, housePrice: val }) }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export const LtvCalculatorComponent = (props: any) => {
  const { config, inFrame } = props;
  const [input, setInput] = useState(defaultInput);
  const output = { ltv: getLtvValue(input) };
  // const { match } = props
  // const hideLink = match && match.params && parseInt(match.params.Id, 10) === 1;
  return (
    <div className={"theme-" + config.theme + " container-fluid"}>
      {inFrame ? null : (<Header config={config}/>)}
      <Centered>
        <ClientDetails config={config} input={input} setInput={setInput} />
        <LevelsOfEnhancement config={config} input={input} output={output} />
      </Centered>
      {(!!config.poweredBy) ? <Footer /> : null}
    </div>
  );
};
